<template>
  <div class="statistics-container">
    <div class="title">
      <div class="main-title">专注儿童主持表演教育15年
      </div>
      <div class="sub-title">线上线下 简单有趣</div>
    </div>
    <img class="img " src="/static/img/mobile/statistics2/bg.png?v=1" />
    <div class="shape">
          <!-- <div class="shape" v-animate="'animate__animated animate__fadeInUp'"> -->
      <img src="/static/img/mobile/statistics2/shape_1.png">
      <img src="/static/img/mobile/statistics2/shape_2.png">
      <img src="/static/img/mobile/statistics2/shape_3.png">
      <img src="/static/img/mobile/statistics2/shape_4.png">
    </div>
    <img class="chat" src="/static/img/mobile/statistics2/chat.png?v=1">
          <!-- <img class="chat" src="/static/img/mobile/statistics2/chat.png?v=1"
      v-animate="'animate__animated animate__fadeInUp animate__delay-1s'"> -->
    <img class="people" src="/static/img/mobile/statistics2/people.png">
          <!-- <img class="people" src="/static/img/mobile/statistics2/people.png"
      v-animate="'animate__animated animate__fadeInUp'"> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.statistics-container {
  width: 100%;
  position: relative;

  .title {
    position: absolute;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 154px;
      font-size: 96px;
      // font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 138px;
      // font-weight: bold;
      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 35px;

      font-size: 58px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 73px;
    }
  }

  .img {
    width: 100%;
  }
  .shape {
    position: absolute;
    top: 511px;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-around;

    img {
      width: 269px;
      height: 269px;
    }
  }

  .chat {
    position: absolute;
    top: 883.2px;
    left: 72.96px;

    width: 518.4px;
    height: 238.08px;
  }

  .people {
    position: absolute;
    right: 0;
    bottom: 30px;

    width: 1136.64px;
    height: 998.4px;
  }
}
</style>