<template>
  <div class="course-container">
    <div class="course">
      <div class="title">
        <div class="main-title">网课<font> /ONLINE COURSE</font></div>
        <div class="sub-title">优师主讲 生动有趣</div>
      </div>
      <!-- <div class="categorys">
        <div class="category">
          <img src="/static/img/mobile/category_1.png">
          <span>演播课程</span>
        </div>
        <div class="category">
          <img src="/static/img/mobile/category_2.png">
          <span>口语课程</span>
        </div>
        <div class="category">
          <img src="/static/img/mobile/category_3.png">
          <span>特色课程</span>
        </div>
      </div> -->
      <div class="teachers">
        <el-carousel
          ref="carousel"
          @change="changeCarouselHandle"
          arrow="always"
          indicator-position="none"
          height="100%"
          :interval="50000"
        >
          <el-carousel-item
            style="margin: 0 5px"
            v-for="(item, key) in args"
            :key="key"
          >
            <CourseItem :item="item" />
          </el-carousel-item>
        </el-carousel>
        <div class="indicator">
          <div
            @click="changeCarouselIndex(key2)"
            :class="{ point: true, actived: carouselIndicatorIndex == key2 }"
            v-for="(idx2, key2) in args"
            :key="key2"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "./course_item.vue";
export default {
  components: {
    CourseItem,
  },
  data() {
    return {
      carouselShow: true,
      carouselIndicatorIndex: 0,
      carouselIndex: 1,
    };
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeCarouselHandle(idx) {
      this.carouselIndicatorIndex = idx;
    },
    changeCarouselIndex(index) {
      console.log(index);
      if (index > this.coursesPage - 1) {
        index = 0;
      }
      if (index < 0) {
        index = this.coursesPage - 1;
      }
      this.carouselIndicatorIndex = index;
      this.$refs.carousel.setActiveItem(this.carouselIndicatorIndex);
    },
  },
  created() {
    console.log(123123);
  },
};
</script>

<style lang="scss" scoped>
.course-container {
  background: #fff5ef;

  .course {
    padding-bottom: 80px;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 150px;
        font-size: 73px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 100px;
        // font-weight: bold;
        font {
          color: $font-color-orange;
        }
      }
      .sub-title {
        margin-top: 19px;
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 76px;
      }
    }
    .categorys {
      display: flex;
      justify-content: center;
      margin-top: 96px;

      .category {
        width: 384px;
        height: 115px;
        border-radius: 58px;
        border: 2px solid rgba($color: #ff6f1b, $alpha: 0.5);

        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 38px;
        &:last-child {
          margin-right: 0;
        }

        img {
          width: 84px;
          height: 84px;
          margin-right: 23px;
        }
        span {
          font-size: 50px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff6f1b;
          line-height: 73px;
        }
      }
    }
    .teachers {
      margin-top: 123px;
      height: 1340px;

      display: flex;
      justify-content: center;

      position: relative;

      .el-carousel {
        width: 1344px;
        height: 100%;

        .el-carousel__item {
          margin: 0 !important;
        }
      }

      .indicator {
        position: absolute;
        bottom: 28px;
        z-index: 13;

        display: flex;
        justify-content: center;

        transition: 1s;

        .point {
          width: 31px;
          height: 31px;
          background-color: #ffffff;
          // background-color: rgba($color: $bg-color-orange, $alpha: 0.1);
          margin: 0 0 0 31px;
          border-radius: 100%;
          transition: 0.5s;
          cursor: pointer;

          &:hover {
            transform: scale(1.25);
          }

          &.actived {
            background-color: rgba($color: $bg-color-orange, $alpha: 1);
          }
        }
      }
    }
  }
}
</style>