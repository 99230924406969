<template>
  <div class="news-container">
    <div class="title">
      <div class="main-title">新闻<font>/NEWS</font>
      </div>
      <div class="sub-title">企业动态 及时报道</div>
    </div>
    <div class="news">
      <el-carousel @change="changeCarousel" :interval="4000" height="100%" indicator-position="none" arrow="always">
        <el-carousel-item v-for="(item,idx) in args" :key="idx">
          <NewsItem :item="item" :showText="idx == carouselIdx" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="more" @click="lookAll">
      查看全部
    </div>
  </div>
</template>

<script>
import NewsItem from "./news_item.vue";
export default {
  components: {
    NewsItem,
  },
  data() {
    return {
      carouselIdx: 0,
    };
  },
  methods: {
    lookAll() {
      this.$router.push({ path: "/mobile/news_list" });
    },
    changeCarousel(idx) {
      this.carouselIdx = idx;
    },
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.news-container {
  position: relative;
  overflow: hidden;
  // background: #fff5ef;
  height: 1775px;
  height: 2100px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 200px;
      font-size: 73px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 100px;
      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 19px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 76px;
    }
  }

  .news {
    // overflow: hidden;
    width: 100%;
    margin-top: 115px;
    height: 1200px;

    // display: flex;
    // justify-content: center;

    .el-carousel {
      width: 100%;
      height: 100%;

      ::v-deep .el-carousel__arrow {
        top: 40%;
      }
      .el-carousel__item {
        width: 100%;
      }
    }
  }

  > .more {
    width: 442px;
    height: 134px;
    border-radius: 83px;
    border: 3px solid #ff6f1b;

    font-size: 58px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6f1b;
    line-height: 81px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 108px auto 154px auto;
  }
}
</style>