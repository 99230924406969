<template>
  <div class="about">
    <div class="title">
      <div class="main-title">我们<font>/ABOUT US</font>
      </div>
      <div class="sub-title">传播汉语 赋能未来</div>
    </div>
    <div class="cover" @click="showFullScreenVideo(args.video)">
      <div class="mask">
        <img class="open" src="/static/img/mobile/open.png" />
      </div>
      <img class="pic" :src="args.cover">
    </div>
    <div class="name">北京筒筒教育科技有限公司</div>
    <div class="intro">
      {{args.content}}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    args: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding: 0 86px;
  width: 1268px;
  padding-bottom: 192px;
  // background: #fff5ef;

  > .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 154px;
      font-size: 73px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 100px;
      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 19px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 76px;
    }
  }

  > .cover {
    margin: 0 -34px;
    margin-top: 90px;
    border-radius: 43px;
    overflow: hidden;
    position: relative;

    .mask {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      .open {
        width: 115px;
        height: 115px;
      }
    }

    .pic {
      width: 1344px;
      height: 899px;
      border-radius: 43px;
    }
  }

  > .name {
    margin-top: 92px;
    font-size: 65px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6f1b;
    line-height: 100px;
  }

  > .intro {
    text-align: justify;
    margin-top: 120px;
    font-size: 58px;
    font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 500;
    color: #333333;
    line-height: 100px;
  }
}
</style>