<template>
  <div class="activity-container">
    <img class="top" src="/static/img/mobile/activity2/top_bg.png">

    <div class="content">
      <div class="title">
        <div class="main-title">大赛<font>/CONTEST</font>
        </div>
        <div class="sub-title">年度盛会 卫视首播</div>
      </div>
      <div class="carousel" @click="lookVideoAndHandler(args[prefix + '_video'])">
        <div class="open">
          <img src="/static/img/mobile/open.png" />
        </div>
        <img class="cover" :src="args[prefix + '_cover']" />
      </div>
      <div class="activity-title">
        {{args[prefix + "_title"]}}
      </div>
      <div class="activity-intro">
        <div class="issue_time">{{dateFormat(args.issue_time, 'y / m / d')}}</div>
        <div class="look_num">
          <img src="/static/img/mobile/eye.png" />
          {{args.look_num}}
        </div>
      </div>
      <div class="activity-content">
        {{intro}}...
        <!-- <span class="more" @click="showActivityMore = true" v-show="!showActivityMore">更多</span> -->
        <!-- <span class="more" @click="showActivityMore = false" v-show="showActivityMore">收回</span> -->
      </div>
      <div class="more" @click="lookAll">
        查看全部
      </div>

    </div>
    <img class="bottom" src="/static/img/mobile/activity2/bottom_bg.png">

  </div>
</template>

<script>
export default {
  data() {
    return {
      showActivityMore: false,
    };
  },
  computed: {
    prefix() {
      if (this.args.show_home == 0) {
        return "open";
      }
      if (this.args.show_home == 1) {
        return "open";
      }

      return "end";
    },
    intro() {
      if (!this.args.intro) {
        return "";
      }
      return this.args.intro;
      // if (this.showActivityMore) {
      // return this.args.intro;
      // }
      // return this.args.intro.substr(0, 60);
    },
  },
  props: {
    args: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    args(args) {
      var slide = decodeURIComponent(args.slide);
      if (!slide) {
        slide = "[]";
      }
      slide = JSON.parse(slide);
      this.args.slide = slide;
    },
  },
  methods: {
    lookVideoAndHandler(src) {
      this.$api("/official/page/inc_look_num", { id: this.args.id });
      this.args.look_num++;
      this.showFullScreenVideo(src);
    },
    lookAll() {
      this.$router.push({
        path: "/mobile/activity",
        query: { content: this.args.intro },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-container {
  display: flex;
  flex-direction: column;

  .top {
    margin: 0;
    height: auto;
    width: 100%;
  }

  .content {
    background: #fff5ef;
    padding: 0 86px;

    > .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 154px;
        font-size: 73px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 100px;
        font {
          color: $font-color-orange;
        }
      }
      .sub-title {
        margin-top: 19px;
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 76px;
      }
    }

    .carousel {
      height: 900px;

      // padding-top: 81px;
      margin: 0 -36px;

      cursor: pointer;

      width: calc(100% + 72px);
      display: flex;
      justify-content: center;

      position: relative;

      border-radius: 46px;
      overflow: hidden;

      margin-top: 68px;

      > .cover {
        width: 100%;

        // width: 1342px;
        // height: 950px;
        border-radius: 46px;
      }

      > .open {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 115px;
          height: 115px;
        }
      }
      > .title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        background: rgba($color: #000000, $alpha: 0.56);
        padding: 31px 38px 27px 38px;

        bottom: 0;
        font-size: 58px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 81px;
      }
    }

    .activity-title {
      margin-top: 69px;
      font-size: 65px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6f1b;
      line-height: 92px;
    }
    .activity-intro {
      margin-top: 30px;
      display: flex;
      align-items: center;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 69px;

      .look_num {
        img {
          width: 74px;
          height: 50px;
        }

        margin-left: 115px;
      }
    }

    .activity-content {
      text-align: justify;

      margin: 60px 0 0 0;

      font-size: 58px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 100px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      .more {
        color: #ff6f1b !important;
        font-size: 0.9em;
        text-decoration: underline;
      }
    }

    .activity-video-title {
      margin-top: 115px;
      display: flex;
      align-items: center;

      font-size: 65px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6f1b;
      line-height: 92px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .line {
        // margin-top: 4px;
        margin-left: 15px;
        flex: 1;
        height: 8px;
        background: rgba($color: #dcdcdc, $alpha: 0.5);
        // border: 2px solid #dcdcdc;
      }
    }

    > .more {
      width: 442px;
      height: 134px;
      border-radius: 83px;
      border: 3px solid #ff6f1b;

      font-size: 58px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff6f1b;
      line-height: 81px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin: 115px auto 115px auto;
    }
  }

  .bottom {
    height: auto;
    width: 100%;
  }
}
</style>