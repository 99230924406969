<template>
  <div class="mobile_course1_container">
    <div class="title">
      <div class="main-title">课程<font>/COURSE</font>
      </div>
    </div>
    <div class="block_center">
      <div class="tabs">
        <div :class="{actived:tabIdx == 0}" @click="changeTabIdx(0)">主持表演课</div>
        <div :class="{actived:tabIdx == 1}" @click="changeTabIdx(1)">特色精品课</div>
      </div>
    </div>
    <div class="items">
      <div class="item1" v-show="tabIdx == 0">
        <img src="/static/img/mobile/course1/studio_course.png"/>
                <!-- <img src="/static/img/mobile/course1/studio_course.png" v-animate="'animate__animated animate__fadeInUp'" /> -->
      </div>
      <div class="item2" v-show="tabIdx == 1">
        <div class="img-title"><span style="color:#FF6F1B;">7-12岁</span>小班精品课</div>
        <img src="/static/img/mobile/course1/special_course.png?v=1" />       
                  <!-- <img src="/static/img/mobile/course1/special_course.png?v=1"
          v-animate="'animate__animated animate__fadeInUp'" />                                      -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIdx: 0,
      activityList: [],
      showActivityList: true,
    };
  },
  created() {
    this.$api("/official/page/mobile_activity").then(({ data }) => {
      this.activityList = data;
    });
  },
  methods: {
    changeTabIdx(idx) {
      this.tabIdx = idx;

      this.showActivityList = false;
      setTimeout(() => {
        this.showActivityList = true;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
.block_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile_course1_container {
  > .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 77px;
      font-size: 73px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 100px;
      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 19px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 76px;
    }
  }

  .tabs {
    margin-top: 96px;

    width: 900px;
    height: 134px;
    border-radius: 68px;
    border: 2px solid #ff6f1b;

    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff6f1b;

    display: flex;
    justify-content: space-between;

    position: relative;

    div {
      flex: 1;
      border-radius: 68px;
      border: 2px solid #ff6f1b;
      padding: 31px 90px;

      display: flex;
      align-items: center;
      justify-content: center;

      &.actived {
        background-color: #ff6f1b;
        color: white;
      }

      &:not(.actived) {
        border: 2px solid white;
      }
    }
  }

  .items {
    .item1 {
      margin-top: 173px;
      margin-bottom: 177px;
    }
    .item2 {
      .img-title {
        text-align: center;
        font-size: 65px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333;
        line-height: 73px;
        margin-bottom: 116px;
      }

      margin-top: 140px;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  .activityList {
    opacity: 0;

    &.show {
      transition: 0.5s;
      opacity: 1;
    }
  }
}
</style>