<template>
  <div class="news-item-container" @click="lookInfo">
    <div class="button">查看详情</div>
    <img class="cover" :src="item.cover" />
    <div class="title" v-show="showText">{{item.title}}</div>
    <div class="time" v-show="showText">{{dateFormat(item.issue_time, 'y / m / d')}}</div>
    <!-- <div class="more" @click="lookInfo">
      查看详情
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    showText: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    itme(val) {
      console.log(val);
    },
  },
  methods: {
    lookInfo() {
      const id = this.item.id;
      this.$router.push({ path: "/mobile/news_info", query: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.news-item-container {
  // width: 100%;
  width: 1342px;
  margin: 0 auto;

  .cover {
    width: 100%;
    height: 900px;
    box-shadow: 0px 0px 38px 0px rgba(234, 77, 0, 0.2);
    border-radius: 35px;
  }
  .title {
    margin-left: 23px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    width: 100%;
    margin-top: 40px;

    font-size: 65px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 92px;
  }
  .time {
    margin-left: 23px;

    margin-top: 20px;

    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 69px;
  }

  .button {
    position: absolute;
    right: 100px;
    top: 775px;

    width: 259px;
    height: 84px;
    background: #ff6f1b;
    border-radius: 42px;
    opacity: 0.75;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 42px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 61px;
  }

  // .more {
  //   width: 461px;
  //   height: 134px;
  //   border-radius: 83px;
  //   border: 3px solid #ff6f1b;

  //   font-size: 58px;
  //   font-family: PingFangSC-Medium, PingFang SC;
  //   font-weight: 500;
  //   color: #ff6f1b;
  //   line-height: 81px;

  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   margin: 115px auto 115px auto;
  // }
}
</style>>
