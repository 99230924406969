<template>
  <div class="banner_logo_container">
    <div class="content">
      <img class="logo" src="/static/img/mobile/banner_logo/logo.png?v=1" />
      <!-- <div clas·s="button" @click="skipUrl(args.online_course_applet)">筒筒网课</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    args: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {},
  methods: {
    skipUrl(url) {
      location.href = url;
    },
  },
};
</script>

<style scoped lang="scss">
.banner_logo_container {
  height: 230px;

  .content {
    padding: 58px;

    display: flex;
    justify-content: space-between;

    .logo {
      width: 340px;
      height: 115px;

      transition: 0.5s;

      // &:active {
      //   transform: scale(1.1);
      // }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 306px;
      height: 115px;
      background: linear-gradient(153deg, #ffa83a 0%, #ff6f1b 100%);
      border: 1px solid rgba($color: #fff, $alpha: 0);
      border-radius: 58px;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 69px;

      transition: 0.5s;

      &:active {
        border: 1px solid rgba($color: #ff6f1b, $alpha: 1);
        color: #ff6f1b;
        background: none;
      }
    }
  }
}
</style>