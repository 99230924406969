<template>
  <div class="course_item_container">
    <div class="teacher">
      <div class="stars">
        推荐指数
        <img class="star" src="/static/img/mobile/star.png" v-for="key in 5" :key="key" />
      </div>
      <img class="badge" src="/static/img/mobile/teacher_now.png">
      <img class="cover" v-lazy="item.mobile_pic">
      <div class="button" @click="showFullScreenVideo(item.try_video)">
        <div class="icon"></div>
        免费试看
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    info() {
      document.documentElement.scrollTo(
        0,
        document.documentElement.scrollHeight
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.course_item_container {
  height: 1220px;
  width: 1344px;

  display: flex;
  align-items: center;

  .teacher {
    height: 1220px;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    .cover {
      // width: 1334px;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .stars {
      position: absolute;
      top: 85px;
      right: 10px;

      display: flex;
      align-items: center;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 69px;

      .star {
        width: 50px;
        height: 58px;
        margin-left: 19px;
      }
    }

    .badge {
      position: absolute;
      right: 0px;
      top: 260px;

      width: 284px;
      height: 96px;
      background: linear-gradient(180deg, #ffd36c 0%, #ffa83a 100%);
      box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.11);
      border-radius: 48px 0px 0px 48px;
    }

    .button {
      cursor: pointer;
      outline: none;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 110px;
      right: 180px;

      width: 453px;
      height: 134px;
      background: #ffffff;
      border-radius: 67px;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff4d00;
      line-height: 69px;

      .icon {
        width: 58px;
        height: 46px;
        background: url(/static/img/mobile/video_icon.png);
        background-size: 100%;
        margin-right: 19px;
      }

      transition: 0.5s;

      &:active {
        transform: scale(1.1);
        background: $font-color-orange;
        border: 1px solid $border-color-orange;
        color: white;
        font-weight: bold;

        .icon {
          background: url(/static/img/mobile/video_icon_hover.png);
          background-size: 100%;
        }
      }
    }
  }
}
</style>