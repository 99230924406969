<template>
  <div class="mobile_container">
    <BannerLogo :args="info.banner_logo" />
    <!-- <Cover :args="info.cover" /> -->
    <!-- <Statistics /> -->
    <Statistics2 />
    <!-- <Course :args="info.course" /> -->
    <Course1 />
    <OnlineCourse :args="info.teacher" />
    <News :args="info.news" />
    <!-- <Activity :args="info.activity" /> -->
    <Activity2 :args="info.activity" />
    <About :args="info.about" />
  </div>
</template>

<script>
import BannerLogo from "@/components/mobile/banner_logo.vue";
// import Statistics from "@/components/mobile/statistics.vue";
import Statistics2 from "@/components/mobile/statistics2.vue";
// import Cover from "@/components/mobile/cover.vue";
// import Course from "@/components/mobile/course.vue";
import OnlineCourse from "@/components/mobile/online_course.vue";
import News from "@/components/mobile/news.vue";
import About from "@/components/mobile/about.vue";
// import Activity from "@/components/mobile/activity.vue";
import Activity2 from "@/components/mobile/activity2.vue";
import Course1 from "@/components/mobile/course1.vue";
export default {
  components: {
    BannerLogo,
    // Cover,
    // Course,
    Course1,
    OnlineCourse,
    News,
    About,
    // Activity,
    Activity2,
    // Statistics,
    Statistics2,
  },
  data() {
    return {
      showLeague: false,
      info: {},
    };
  },
  created() {
    //宽度判断如果大于768则展示pc
    if (document.documentElement.clientWidth > 768) {
      this.$router.replace({ path: "/home" });
    }

    this.$api("/official/page/mobile2").then(({ data }) => {
      this.info = data;
    });
  },
  computed: {
    intro() {
      if (this.showFull) {
        return "";
      }

      return "";
    },
  },
  methods: {
    skipUrl(url) {
      location.href = url;
    },
    checkFull() {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile_container {
  display: flex;
  flex-direction: column;

  // padding-bottom: 300px;

  .img {
    width: 100%;
  }

  .about {
    padding-bottom: 414px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-title {
        padding-top: 80px;
        font-size: 73px;
        color: $font-color-black;

        font {
          color: $font-color-orange;
        }
      }
      .sub-title {
        margin-top: 10px;
        font-size: $font-size-16;
        color: $font-color-gray;
      }
    }
    .cover {
      margin-top: 98px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        background: rgba($color: #000000, $alpha: 0.2);

        width: 1344px;
        height: 899px;
        box-shadow: 0px 4px 42px 0px rgba(234, 77, 0, 0.2);
        border-radius: 43px;
        margin-left: 47px;

        display: flex;
        align-items: center;
        justify-content: center;
        .open {
          width: 150px;
          height: 150px;
        }
      }

      .pic {
        width: 1344px;
        height: 899px;
        box-shadow: 0px 4px 42px 0px rgba(234, 77, 0, 0.2);
        border-radius: 43px;
      }
    }

    .name {
      margin: 0 85px;
      margin-top: 96px;
      color: #ff6f1b;
      font-size: 65px;
      font-weight: bold;
      line-height: 92px;
    }
    .intro {
      margin: 0 85px;

      margin-top: 120px;
      color: #333;
      font-size: 58px;
      line-height: 92px;

      .more {
        color: $font-color-orange;
      }
    }
  }
}
</style>